<template>
  <nav
    class="fixed top-0 w-full bg-white border-b border-gray-200"
    style="z-index: 70"
  >
    <div class="px-3 py-3 lg:px-5 lg:pl-3">
      <div class="flex items-center justify-between">
        <div class="flex items-center justify-start">
          <button
            data-drawer-target="logo-sidebar"
            data-drawer-toggle="logo-sidebar"
            aria-controls="logo-sidebar"
            type="button"
            class="sidebar inline-flex items-center p-2 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
            @click="openSideBar"
          >
            <span class="sr-only">Open sidebar</span>
            <!--if rtl add flip-horizontal to svg class-->
            <svg
              class="w-6 h-6"
              aria-hidden="true"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                clip-rule="evenodd"
                fill-rule="evenodd"
                d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
              ></path>
            </svg>
          </button>
          <!-- in rtl change(md:mr-24 to md:ml-24)-->
          <router-link to="/" class="flex mx-2">
            <img
              src="/assets/image/kcStore.png"
              alt=""
              class="w-16 lg:w-20 mx-3"
            />
          </router-link>
        </div>

        <div class="flex items-center">
          <!--Notification-->
          <div class="flex items-center ml-2">
            <div class="mx-2">
              <router-link
                to="/products"
                class="flex relative text-sm rounded-full text-gray-600 notification-modal"
                ><button
                  class="my_font my_font_size flex font-medium py-2 px-4 top-3 left-45precent rounded-4 tracking-tight text-white bg-gradient-to-br from-primaryColor to-primaryColorDark"
                >
                  {{ $t("product") }}
                </button></router-link
              >
            </div>
            <div class="mx-2">
              <button
                type="button"
                class="flex relative text-sm rounded-full text-gray-600 notification-modal"
                @click="openNotification"
              >
                <span class="sr-only">Notification menu</span>
                <svg
                  class="w-6 h-6"
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M10 2a6 6 0 00-6 6v3.586l-.707.707A1 1 0 004 14h12a1 1 0 00.707-1.707L16 11.586V8a6 6 0 00-6-6zM10 18a3 3 0 01-3-3h6a3 3 0 01-3 3z"
                  ></path>
                </svg>
                <span
                  v-if="checkIfAnyNotififcationNotRead"
                  aria-hidden="true"
                  class="absolute top-0 right-0 inline-block w-3 h-3 transform translate-x-1 -translate-y-1 bg-red-600 border-2 border-white rounded-full"
                ></span>
              </button>
            </div>

            <div
              :class="
                ' h-72 overflow-y-auto fixed top-10  my-4 text-base list-none bg-white divide-y space-y-2 divide-gray-100 rounded shadow notification-modal ' +
                (!isRtl ? ' right-1' : ' left-1')
              "
              v-show="isShowModalNotification"
            >
              <!-- Notification design-->
              <a
                :class="'flex items-start py-2 px-2 hover:bg-gray-100 cursor-pointer '"
                role="none"
                v-for="(notifi, index) in notifications"
                :key="index"
                :href="notifi.n_action"
              >
                <span
                  :class="
                    'justify-center  rounded-full text-white text-sm py-1.5 px-2 ' +
                    (notifi.n_type == 'pending'
                      ? 'bg-gradient-to-br from-gray-100 to-gray-200 text-gray-600'
                      : notifi.n_type == 'accepted'
                      ? 'bg-gradient-to-br from-green-100 to-green-200 text-green-600'
                      : notifi.n_type == 'canceled'
                      ? 'bg-gradient-to-br from-orange-100 to-orange-200 text-orange-600'
                      : notifi.n_type == 'rejected'
                      ? 'bg-gradient-to-br from-red-100 to-red-200 text-red-600'
                      : 'bg-gradient-to-br from-gray-100 to-gray-200 text-gray-600')
                  "
                >
                  <span
                    aria-hidden="true"
                    class="absolute inline-block w-4 h-4 transform translate-x-2 -translate-y-4 bg-primaryColor border-2 border-white rounded-full"
                    v-show="notifi.n_is_read == 0"
                  ></span>
                  <font-awesome-icon
                    :class="
                      'w-3 h-3 my-auto mx-1 ' +
                      (notifi.n_type == 'pending'
                        ? 'text-gray-600'
                        : notifi.n_type == 'accepted'
                        ? 'text-green-600'
                        : notifi.n_type == 'canceled'
                        ? 'text-orange-600'
                        : notifi.n_type == 'rejected'
                        ? 'text-red-600'
                        : 'text-gray-600')
                    "
                    :icon="[
                      'fas',
                      notifi.n_type == 'pending'
                        ? 'clock'
                        : notifi.n_type == 'accepted'
                        ? 'check'
                        : notifi.n_type == 'canceled'
                        ? 'rotate-left'
                        : notifi.n_type == 'rejected'
                        ? 'xmark'
                        : 'info',
                    ]"
                    size="sm"
                  />
                </span>
                <div class="w-2"></div>
                <div class="flex-col space-y-0">
                  <div class="flex justify-between">
                    <span
                      class="my_font text-base text-gray-700 w-68"
                      role="none"
                    >
                      {{ $t(notifi.n_title) }}
                    </span>
                    <span class="my_font text-xs">
                      {{ localTime(notifi.created_at) }}
                    </span>
                  </div>
                  <p class="my_font text-sm text-gray-500 w-68" role="none">
                    {{ $t(notifi.n_desc) }}
                  </p>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
  <div
    v-if="showSideBar"
    class="fixed top-0 left-0 w-full h-full bg-black opacity-50"
    style="z-index: 60"
  ></div>
  <!--if rtl change (left-0 to right-0) (-translate-x-full to translate-x-full) (border-l to border-r)-->
  <aside
    id="logo-sidebar"
    :class="
      'sidebar   fixed top-16 md:top-0    w-64 h-screen pt-4 transition-transform  bg-white  border-gray-200 sm:translate-x-0 ' +
      (isRtl
        ? ' right-0 border-l translate-x-0 '
        : ' left-0 border-r -translate-x-0 ') +
      (showSideBar ? '' : ' hidden xs:hidden custom-div-show md:block ')
    "
    aria-label="Sidebar"
    style="z-index: 70"
  >
    <div class="h-full px-4 pt-3 pb-4 overflow-y-auto bg-white">
      <ul class="space-y-3 font-medium">
        <li class="">
          <!--in rtl change (active-dashboard to active-r-dashboard)-->
          <router-link
            to="/profile"
            :class="
              'flex items-center px-2 py-3 text-gray-900 rounded-lg hover:bg-gray-100 ' +
              (currentPath() == '/profile' ? ' active-dashboard ' : '')
            "
          >
            <svg
              aria-hidden="true"
              class="flex-shrink-0 w-6 h-6 text-gray-600 transition duration-75 group-hover:text-gray-900"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <span class="mx-3 text-lg my_font">{{ $t("my_profile") }}</span>
          </router-link>
        </li>
        <li>
          <router-link
            to="/profile/cart"
            :class="
              'flex items-center px-2 py-3 text-gray-900 rounded-lg hover:bg-gray-100 ' +
              (currentPath() == '/profile/cart' ? ' active-dashboard ' : '')
            "
          >
            <svg
              aria-hidden="true"
              class="flex-shrink-0 w-6 h-6 text-gray-600 transition duration-75 group-hover:text-gray-900"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M10 2a4 4 0 00-4 4v1H5a1 1 0 00-.994.89l-1 9A1 1 0 004 18h12a1 1 0 00.994-1.11l-1-9A1 1 0 0015 7h-1V6a4 4 0 00-4-4zm2 5V6a2 2 0 10-4 0v1h4zm-6 3a1 1 0 112 0 1 1 0 01-2 0zm7-1a1 1 0 100 2 1 1 0 000-2z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <span class="mx-3 text-lg my_font">{{ $t("my_cart") }}</span>
          </router-link>
        </li>
        <li>
          <router-link
            to="/profile/favorites"
            :class="
              'flex items-center px-2 py-3 text-gray-900 rounded-lg hover:bg-gray-100 ' +
              (currentPath() == '/profile/favorites'
                ? ' active-dashboard '
                : '')
            "
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              class="flex-shrink-0 w-5 h-5 text-gray-600 transition duration-75 group-hover:text-gray-900"
              data-name="Layer 1"
              viewBox="0 0 24 24"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M17.5.917a6.4 6.4 0 0 0-5.5 3.3 6.4 6.4 0 0 0-5.5-3.3A6.8 6.8 0 0 0 0 7.967c0 6.775 10.956 14.6 11.422 14.932l.578.409.578-.409C13.044 22.569 24 14.742 24 7.967a6.8 6.8 0 0 0-6.5-7.05Z"
              />
            </svg>
            <span class="mx-3 text-lg my_font">{{ $t("my_favorites") }}</span>
            <!-- <span
              class="inline-flex items-center justify-center w-3 h-3 p-3 ml-3 text-sm font-medium text-blue-800 bg-blue-100 rounded-full">3</span> -->
          </router-link>
        </li>
        <li>
          <router-link
            to="/profile/orders"
            :class="
              'flex items-center px-2 py-3 text-gray-900 rounded-lg hover:bg-gray-100 ' +
              (currentPath() == '/profile/orders' ? ' active-dashboard ' : '')
            "
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              class="flex-shrink-0 w-6 h-6 text-gray-600 transition duration-75 group-hover:text-gray-900"
              viewBox="0 0 115.35 122.88"
            >
              <path
                d="M25.27 86.92c-1.81 0-3.26-1.46-3.26-3.26s1.47-3.26 3.26-3.26h21.49a3.26 3.26 0 0 1 0 6.52H25.27zm35.83-9.45c-.96 0-1.78-.82-1.78-1.82 0-.96.82-1.78 1.78-1.78h4.83c1.64.04 3.1.36 4.33 1.14 1.37.87 2.37 2.19 2.92 4.15 0 .04 0 .09.05.14l.46 1.82h39.89c1 0 1.78.82 1.78 1.78 0 .18-.05.36-.09.55l-4.65 18.74c-.18.82-.91 1.37-1.73 1.37H79.71c.64 2.37 1.28 3.65 2.14 4.24 1.05.68 2.87.73 5.93.68h20.65c1 0 1.78.82 1.78 1.78 0 1-.82 1.78-1.78 1.78H87.81c-3.79.04-6.11-.05-7.98-1.28-1.92-1.28-2.92-3.46-3.92-7.43L69.8 80.2c0-.05 0-.05-.04-.09-.27-1-.73-1.69-1.37-2.05-.64-.41-1.5-.59-2.51-.59H61.1zm41.99 36.66c2.42 0 4.38 1.96 4.38 4.38s-1.96 4.38-4.38 4.38-4.38-1.96-4.38-4.38 1.96-4.38 4.38-4.38zm-19.2 0c2.42 0 4.38 1.96 4.38 4.38s-1.96 4.38-4.38 4.38-4.38-1.96-4.38-4.38 1.97-4.38 4.38-4.38zM25.27 33.58a3.26 3.26 0 0 1-3.26-3.26c0-1.8 1.47-3.26 3.26-3.26h50.52a3.26 3.26 0 0 1 0 6.52H25.27zM7.57 0H93.2c2.09 0 3.99.85 5.35 2.21s2.21 3.26 2.21 5.35v59.98h-6.5V7.59c0-.29-.12-.56-.31-.76-.2-.19-.47-.31-.76-.31H7.57c-.29 0-.56.12-.76.31s-.3.47-.3.76v98.67c0 .29.12.56.31.76s.46.31.76.31h55.05c.61 2.39 1.3 4.48 2.23 6.47H7.57c-2.09 0-3.99-.85-5.35-2.21A7.484 7.484 0 0 1 0 106.25V7.57c0-2.09.85-4 2.21-5.36S5.48 0 7.57 0zm17.7 60.25c-1.81 0-3.26-1.46-3.26-3.26s1.47-3.26 3.26-3.26h50.52a3.26 3.26 0 0 1 0 6.52H25.27z"
              />
            </svg>
            <span class="mx-3 text-lg my_font">{{ $t("my_orders") }}</span>
          </router-link>
        </li>
        <li>
          <router-link
            to="/profile/settings"
            class="flex items-center px-2 py-3 text-gray-900 rounded-lg hover:bg-gray-100"
            :class="
              'flex items-center px-2 py-3 text-gray-900 rounded-lg hover:bg-gray-100 ' +
              (currentPath() == '/profile/settings' ? ' active-dashboard ' : '')
            "
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xml:space="preserve"
              fill="currentColor"
              class="flex-shrink-0 w-5 h-5 text-gray-600 transition duration-75 group-hover:text-gray-900"
              viewBox="0 0 512 512"
            >
              <path
                d="M34.283 384c17.646 30.626 56.779 41.148 87.405 23.502.021-.012.041-.024.062-.036l9.493-5.483A191.5106 191.5106 0 0 0 192 437.055V448c0 35.346 28.654 64 64 64s64-28.654 64-64v-10.944a191.4942 191.4942 0 0 0 60.757-35.115l9.536 5.504c30.633 17.673 69.794 7.167 87.467-23.467 17.673-30.633 7.167-69.794-23.467-87.467l-9.472-5.461a194.1496 194.1496 0 0 0 0-70.187l9.472-5.461c30.633-17.673 41.14-56.833 23.467-87.467-17.673-30.633-56.833-41.14-87.467-23.467l-9.493 5.483A191.5184 191.5184 0 0 0 320 74.944V64c0-35.346-28.654-64-64-64s-64 28.654-64 64v10.944a191.4942 191.4942 0 0 0-60.757 35.115l-9.536-5.525C91.073 86.86 51.913 97.367 34.24 128s-7.167 69.794 23.467 87.467l9.472 5.461a194.1496 194.1496 0 0 0 0 70.187l-9.472 5.461C27.158 314.296 16.686 353.38 34.283 384zM256 170.667c47.128 0 85.333 38.205 85.333 85.333S303.128 341.333 256 341.333 170.667 303.128 170.667 256s38.205-85.333 85.333-85.333z"
              />
            </svg>
            <span class="mx-3">
              <span class="mx-3 text-lg my_font">{{
                $t("settings")
              }}</span></span
            >
          </router-link>
        </li>
        <li>
          <a
            href=""
            class="curser-pointer flex items-center px-2 py-3 text-gray-900 rounded-lg hover:bg-gray-100"
            @click="logout"
          >
            <svg
              aria-hidden="true"
              class="curser-pointer flex-shrink-0 w-6 h-6 text-gray-600 transition duration-75 group-hover:text-gray-900"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M3 3a1 1 0 00-1 1v12a1 1 0 102 0V4a1 1 0 00-1-1zm10.293 9.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L14.586 9H7a1 1 0 100 2h7.586l-1.293 1.293z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <span class="mx-3 text-lg my_font">{{ $t("logout") }}</span>
          </a>
        </li>
      </ul>
    </div>
  </aside>
</template>

<script>
import { SERVER_URL } from "../../../config.js";
import axios from "axios";
export default {
  data() {
    return {
      url: SERVER_URL,
      isDataLoaded: false,
      notifications: [],
      isShowModalNotification: false,
      showSideBar: false,
    };
  },
  created() {
    this.getNotifications();
  },
  computed: {
    isRtl() {
      return (
        localStorage.getItem("lang") == "ar" ||
        localStorage.getItem("lang") == "ku" ||
        //if null
        localStorage.getItem("lang") == null ||
        //if empty
        localStorage.getItem("lang") == ""
      );
    },
    checkIfAnyNotififcationNotRead() {
      let notRead = this.notifications.filter((item) => {
        return item.n_is_read == 0;
      });
      return notRead.length > 0;
    },
  },
  methods: {
    localTime(timeStamp) {
      let date = new Date(timeStamp);
    
      //convert 7/9/2023, 3:14:47 AM to 7-9-2023 3:14:47

      //then reverse it to 2023-9-7 3:14:47
      //convert to ago time

      return this.$moment(date.toString()).fromNow();
    },
    currentPath() {
      return this.$route.path;
    },
    logout() {
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      window.location.href = "/signin";
    },
    getNotifications() {
      axios
        .post(SERVER_URL + "api/notification/get")
        .then((response) => {
          // this.items = response.data;
          this.notifications = response.data;
          this.isDataLoaded = true;
          // console.log("notifications");
          //  console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
          this.isDataLoaded = true;
        });
    },
    changeNotificationState() {
      if (!this.checkIfAnyNotififcationNotRead) return;
      axios
        .post(SERVER_URL + "api/notification/state")
        // eslint-disable-next-line no-unused-vars
        .then((response) => {
          //   console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    openNotification() {
      document.addEventListener("click", (e) => {
        if (e.target.closest(".notification-modal")) return;
        this.isShowModalNotification = false;
      });

      this.isShowModalNotification = !this.isShowModalNotification;
      this.changeNotificationState();
      //close dropdwon when click outside
    },
    openSideBar() {
      //   console.log("openSideBar");
      document.addEventListener("click", (e) => {
        if (e.target.closest(".sidebar")) return;
        this.showSideBar = false;
      });

      this.showSideBar = !this.showSideBar;
      //close dropdwon when click outside
    },
  },
};
</script>

<style scoped>
@media (min-width: 640px) and (max-width: 768px) {
  .custom-div-show {
    display: block !important;
  }
}
</style>
