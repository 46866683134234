<template>
  <Navbar />

  <div :class="'p-4 mt-20  ' + (isRtl ? 'sm:mr-64' : 'sm:ml-64')">
    <center class="text-xl my_font" v-if="orders.length == 0 && isDataLoaded">
      <img src="/assets/image/not_found.png" class="w-72" alt="" />
      {{ $t("you_dont_have_any_order") }}
    </center>
    <div
      class="w-11/12 mx-auto grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-8"
    >
      <!--first order-->
      <div
        class="flex flex-col p-5 bg-white border-t-4 border-primaryColor rounded-xl shadow-md"
        data-aos="fade-up"
        data-aos-duration="1000"
        v-for="(order, index) in orders"
        :key="index"
      >
        <h1 class="text-gray-700 font-bold text-2xl leading-8 my-1">
          #{{ order.o_id }}
        </h1>
        <ul class="text-gray-700 py-2 px-1 mt-3 divide-y rounded">
          <li class="flex items-center py-4">
            <span class="my_font">{{ $t("date") }}</span>
            <div class="mx-auto"></div>
            <span class="my_font">{{
              order.created_at.toString().substring(0, 10)
            }}</span>
          </li>
          <li class="flex items-center py-4">
            <span class="my_font">{{ $t("total_price") }}</span>
            <div class="mx-auto"></div>
            <span class="my_font">{{
              formatNumber(
                order.o_price * (1 - order.o_discount) + parseInt(order.o_delivery_fees)
              )
            }}</span>
          </li>
          <li class="flex items-center py-4">
            <div class="my_font">{{ $t("order_status") }}</div>
            <div class="mx-auto"></div>
            <div
              :class="
                'flex justify-center py-2 px-4 rounded-lg text-white text-sm ' +
                (order.o_status == 'pending'
                  ? 'bg-gradient-to-br from-gray-100 to-gray-200 text-gray-600'
                  : order.o_status == 'accepted'
                  ? 'bg-gradient-to-br from-green-100 to-green-200 text-green-600'
                  : order.o_status == 'canceled'
                  ? 'bg-gradient-to-br from-orange-100 to-orange-200 text-orange-600'
                  : 'bg-gradient-to-br from-red-100 to-red-200 text-red-600')
              "
            >
              <font-awesome-icon
                :class="
                  'w-3 h-3 my-auto mx-1 ' +
                  (order.o_status == 'pending'
                    ? 'text-gray-600'
                    : order.o_status == 'accepted'
                    ? 'text-green-600'
                    : order.o_status == 'canceled'
                    ? 'text-orange-600'
                    : 'text-red-600')
                "
                :icon="[
                  'fas',
                  order.o_status == 'pending'
                    ? 'clock'
                    : order.o_status == 'accepted'
                    ? 'check'
                    : order.o_status == 'canceled'
                    ? 'rotate-left'
                    : 'xmark',
                ]"
                size="sm"
              />
              <span
                :class="
                  'my_font text-3.5 font-medium my-auto ' +
                  (order.o_status == 'pending'
                    ? 'text-gray-600'
                    : order.o_status == 'accepted'
                    ? 'text-green-600'
                    : order.o_status == 'canceled'
                    ? 'text-orange-600'
                    : 'text-red-600')
                "
                >{{ $t(order.o_status) }}</span
              >
            </div>
          </li>
          <li class="py-4">
            <a
              class="flex justify-center bg-gradient-to-br from-primaryColor to-pink-400 py-2 px-4 w-11/12 mx-auto rounded-lg text-white text-sm"
              :href="'/profile/orders/' + order.o_id"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                class="w-4 h-4 text-white my-auto"
                viewBox="0 0 24 24"
              >
                <path
                  d="M23.271 9.419C21.72 6.893 18.192 2.655 12 2.655S2.28 6.893.729 9.419a4.908 4.908 0 0 0 0 5.162C2.28 17.107 5.808 21.345 12 21.345s9.72-4.238 11.271-6.764a4.908 4.908 0 0 0 0-5.162Zm-1.705 4.115C20.234 15.7 17.219 19.345 12 19.345S3.766 15.7 2.434 13.534a2.918 2.918 0 0 1 0-3.068C3.766 8.3 6.781 4.655 12 4.655s8.234 3.641 9.566 5.811a2.918 2.918 0 0 1 0 3.068Z"
                />
                <path
                  d="M12 7a5 5 0 1 0 5 5 5.006 5.006 0 0 0-5-5Zm0 8a3 3 0 1 1 3-3 3 3 0 0 1-3 3Z"
                />
              </svg>
              <div class="w-3"></div>
              <span class="my_font text-3.5 font-medium my-auto">{{
                $t("view_order")
              }}</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "./components/nav-bar.vue";

import "swiper/swiper-bundle.min.css";
import { SERVER_URL } from "../../config.js";
import axios from "axios";
//import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

export default {
  name: "App",

  components: {
    Navbar,
  },
  data() {
    return {
      url: SERVER_URL,
      isDataLoaded: false,
      favorites: [],
      orders: [],
    };
  },
  computed: {
    isRtl() {
      return (
        localStorage.getItem("lang") == "ar" ||
        localStorage.getItem("lang") == "ku" ||
        //if null
        localStorage.getItem("lang") == null ||
        //if empty
        localStorage.getItem("lang") == ""
      );
    },
  },
  created() {
    this.getOrders();
  },
  methods: {
    formatNumber(num) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },
    getOrders() {
      this.isDataLoaded = false;
      axios
        .post(SERVER_URL + "api/order/get-mine")
        .then((response) => {
          // this.items = response.data;
          this.orders = response.data;
          this.isDataLoaded = true;

        })
        .catch((error) => {
          console.log(error);
          this.isDataLoaded = true;
        });
    },
  },
};
</script>

<style></style>
