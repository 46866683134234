<template>
  <div>
    <!-- Header Area wrapper Starts -->
    <header id="header-wrap" class="relative my_font my_font_size">
      <!-- Navbar Start -->
      <div
        class="navigation sticky top-0 left-0 px-2 py-2 md:px-2 md:py-2 lg:py-0 w-full z-30 duration-300"
      >
        <div class="mx-4">
          <nav
            class="navbar navbar-expand-lg flex justify-between items-center relatve duration-300 navbar-toggler"
          >
            <button
              class="navbar-toggler focus:outline-none block lg:hidden relative z-30"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
              @click="toggleNavbar"
            >
              <font-awesome-icon
                :icon="isShowNavbarToggle ? 'times' : 'bars'"
                size="xl"
                class="text-gray-600 font-normal"
              />
            </button>

            <div class="flex md:hidden">
              <!--My card only show when user loginned in-->

              <div
                class="flex md:hidden items-center justify-center bg-white mx-1"
                v-if="isSignedIn"
              >
                <router-link class="relative scale-75" to="/profile/cart">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="h-9 w-9 text-gray-600"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 00-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 00-16.536-1.84M7.5 14.25L5.106 5.272M6 20.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm12.75 0a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
                    />
                  </svg>
                  <span
                    class="absolute -top-2 left-4 rounded-full bg-red-500 p-0.2 px-2 text-sm text-red-50"
                    >{{ checkIfCartHasItems }}</span
                  >
                </router-link>
              </div>

              <div
                class="flex md:hidden items-center justify-center bg-white mx-1"
                v-if="isSignedIn"
              >
                <router-link class="relative scale-75" to="/profile/favorites">
                  <svg
                    class="curser-pointer w-9 h-9 text-gray-600"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20.16 5A6.29 6.29 0 0 0 12 4.36a6.27 6.27 0 0 0-8.16 9.48l6.21 6.22a2.78 2.78 0 0 0 3.9 0l6.21-6.22a6.27 6.27 0 0 0 0-8.84Zm-1.41 7.46-6.21 6.21a.76.76 0 0 1-1.08 0l-6.21-6.24a4.29 4.29 0 0 1 0-6 4.27 4.27 0 0 1 6 0 1 1 0 0 0 1.42 0 4.27 4.27 0 0 1 6 0 4.29 4.29 0 0 1 .08 6Z"
                    />
                  </svg>
                </router-link>
              </div>

              <div class="w-4"></div>
              <!--
                router link>
               </-->

              <a class="flex md:hidden navbar-brand" href="/">
                <img
                  src="../../assets/image/kcStore.png"
                  alt="Logo"
                  class="w-16"
                />
              </a>
            </div>

            <div
              :class="
                (isRtl ? 'right-0' : ' left-0 ') +
                ' collapse navbar-collapse   duration-300 shadow fixed top-0  mt-full bg-white z-20 px-5 py-16 md:py-10 lg:py-5 h-full w-4/6 md:w-3/6 lg:static lg:bg-transparent lg:shadow-none ' +
                (isShowNavbarToggle ? '' : 'hidden lg:block')
              "
              id="navbarSupportedContent"
            >
              <ul class="navbar-nav mr-auto lg:flex">
                <a class="hidden lg:flex navbar-brand" href="/">
                  <img
                    src="../../assets/image/kcStore.png"
                    alt="Logo"
                    class="w-16"
                  />
                </a>

                <div class="mx-2.5"></div>
                <li class="nav-item">
                  <router-link
                    :class="
                      'page-scroll  my_font my_font_size ' +
                      (currentPath() == '/' ? 'active' : '')
                    "
                    to="/"
                    >{{ $t("home") }}</router-link
                  >
                </li>
                <li class="nav-item my_font my_font_size">
                  <div class="group inline-block drop">
                    <a
                      :class="
                        'page-scroll cursor-pointer dropdown-category ' +
                        (currentPath() == '/products' ? ' active ' : '')
                      "
                      @click="changeCategory"
                    >
                      <span class="my_font my_font_size">{{
                        $t("product")
                      }}</span>
                      <span>
                        <svg
                          class="fill-current h-4 w-4 transform group-hover:-rotate-180 transition duration-150 ease-in-out"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path
                            d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                          />
                        </svg>
                      </span>
                    </a>

                    <!--first list of dropdown-->
                    <ul
                      class="my_font my_font_size bg-white mx-2 lg:mx-0 lg:border rounded-lg lg:transform lg:scale-0 lg:group-hover:scale-100 lg:absolute lg:transition lg:duration-150 lg:ease-in-out lg:origin-top lg:min-w-32 dropdown-category"
                      v-show="isShowCategory"
                    >
                      <li
                        class="my_font my_font_size rounded-lg relative px-3 py-1 page-scroll cursor-pointer"
                        v-for="(category, index) in categorys"
                        :key="index"
                      >
                        <a
                          @click="changeSubCategory(category)"
                          class="my_font my_font_size w-full flex items-center outline-none focus:outline-none dropdown-subCategory"
                        >
                          <span class="flex-1 my_font my_font_size">{{
                            $t(index)
                          }}</span>
                          <span class="mr-auto" v-if="category != 0">
                            <font-awesome-icon
                              icon="angle-left"
                              size="xs"
                              class="mx-1 mt-3"
                              v-if="isRtl"
                            />
                            <font-awesome-icon
                              icon="angle-right"
                              size="xs"
                              class="mx-1 mt-3"
                              v-if="!isRtl"
                            />
                          </span>
                        </a>
                        <!--nested dropdown-->
                        <ul
                          v-show="isShowSubCategory"
                          :class="
                            'bg-white border rounded-lg absolute top-0  transition duration-150 ease-in-out  min-w-32 overflow-y-auto dropdown-subCategory h-120 ' +
                            (isRtl
                              ? ' custom-right origin-top-right'
                              : ' left-10 origin-top-left')
                          "
                        >
                          <a
                            class="my_font px-3 py-1 page-scroll cursor-pointer"
                            v-for="(sub, i) in category"
                            :key="i"
                            :href="
                              i == 0
                                ? '/products?' + 'category=' + index
                                : '/products?' +
                                  'category=' +
                                  index +
                                  '&sub=' +
                                  category[i].sc_id
                            "
                          >
                            {{ category[i].sc_name }}
                          </a>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </li>

                <li class="nav-item">
                  <router-link
                    :class="
                      'page-scroll  my_font my_font_size ' +
                      (currentPath() == '/about' ? 'active' : '')
                    "
                    to="/about"
                    >{{ $t("about") }}</router-link
                  >
                </li>
                <li class="nav-item">
                  <router-link
                    :class="
                      'page-scroll  my_font my_font_size ' +
                      (currentPath() == '/offers' ? 'active' : '')
                    "
                    to="/offers"
                  >
                    {{ $t("offers") }}</router-link
                  >
                </li>

                <!--End login in mobile Screen-->

                <router-link :to="isSignedIn ? '/profile' : '/signin'"
                  ><button
                    class="my_font my_font_size flex md:hidden font-medium py-2 px-4 fixed top-3 left-45precent sm:left-1/2 rounded-4 tracking-tight text-white bg-gradient-to-br from-primaryColor to-primaryColorDark"
                  >
                    {{ $t(isSignedIn ? "my_account" : "sign_in") }}
                  </button></router-link
                >

                <!--start Language dropdown on mobile screen-->
                <li class="nav-item flex md:hidden fixed">
                  <div class="">
                    <a
                      @click="changeLamguageState"
                      class="my_font my_font_size inline-flex justify-center items-center text-gray-700 duration-300 w-full px-3 py-3 cursor-pointer active:text-primaryColor hover:text-primaryColor dropdown-language"
                    >
                      <img :src="$t('flag')" alt="" class="w-6 mx-1" />
                      {{ $t("language") }}
                    </a>
                  </div>

                  <div
                    v-show="isShowLangauge"
                    class="absolute top-8 mt-2 w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
                  >
                    <div
                      class="py-1"
                      role="menu"
                      aria-orientation="vertical"
                      aria-labelledby="language-dropdown"
                      v-show="true"
                    >
                      <buttona
                        class="block px-2 py-2 text-sm text-gray-700 hover:bg-gray-100"
                        role="menuitem"
                        @click="changeLanguage('en')"
                      >
                        <div
                          class="my_font my_font_size inline-flex items-center"
                        >
                          <img
                            src="/assets/image/english.svg"
                            alt=""
                            class="w-7 mx-2"
                          />
                          English
                        </div>
                      </buttona>
                      <button
                        class="block px-2 py-2 text-sm text-gray-700 hover:bg-gray-100"
                        role="menuitem"
                        @click="changeLanguage('ku')"
                      >
                        <div
                          class="my_font my_font_size inline-flex items-center"
                        >
                          <img
                            src="/assets/image/kurdish.png"
                            alt=""
                            class="w-7 mx-2"
                          />
                          Kurdish
                        </div>
                      </button>
                      <button
                        class="block px-2 py-2 text-sm text-gray-700 hover:bg-gray-100"
                        role="menuitem"
                        @click="changeLanguage('ar')"
                      >
                        <div
                          class="my_font my_font_size inline-flex items-center"
                        >
                          <img
                            src="/assets/image/arabic.png"
                            alt=""
                            class="w-7 mx-2"
                          />
                          Arabic
                        </div>
                      </button>
                    </div>
                  </div>
                </li>
                <!--end Language dropdown on mobile screen-->
              </ul>
            </div>
            <!--only in lg screen hidden in mobile view-->
            <div class="hidden md:flex items-center mx-0 md:mx-3">
              <div
                class="hidden md:flex items-center border border-gray-400 rounded-4 px-4 mx-4 py-2"
                v-if="!checkIfProductPage()"
              >
                <input
                  class="my_font my_font_size flex-1 text-gray-700 appearance-none bg-transparent focus:outline-none w-52"
                  type="text"
                  :placeholder="$t('search')"
                  v-model="search"
                  @input="searchFunc()"
                  @keyup.enter="searchFuncGo"
                />
                <svg
                  class="w-5 h-5 text-gray-700"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  @click="searchFuncGo()"
                >
                  <path d="M22 22l-6-6" />
                  <circle cx="10" cy="10" r="8" />
                </svg>
              </div>
              <!--display when write text in search feild and in md and larger screen-->
              <div
                class="md:absolute mx-4 top-16 w-64 h-44 rounded-4 shadow-lg bg-white ring-1 ring-black ring-opacity-5 overflow-y-auto overscroll-y-contain"
                v-if="isSearchOpen"
              >
                <div
                  class="py-1"
                  v-for="(item, index) in searchList"
                  :key="index"
                >
                  <a
                    :href="'/products/' + item.i_id"
                    class="w-full flex items-center py-2 text-3 text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                  >
                    <img
                      :src="
                        url + 'kcstore.online/public/storage/' + item.i_image
                      "
                      alt=""
                      class="w-20 h-14 rounded-3 px-1"
                    />
                    {{ item.i_name }}</a
                  >
                </div>
                <!--
                  loading circle
                -->

                <div
                  class="flex justify-center items-center h-full"
                  v-if="isLoadSearch"
                >
                  <div
                    class="animate-spin rounded-full h-9 w-9 border-b-2 border-pink-500"
                  ></div>
                </div>
                <div
                  class="my_font w-full flex items-center justify-center py-2 text-lg h-full text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                  v-if="searchList.length == 0 && isLoadSearch == false"
                >
                  {{ $t("no_result_found") }}
                </div>
              </div>

              <div class="hidden md:block lg:mr-0" v-if="!isSignedIn">
                <router-link
                  :to="isSignedIn ? '/profile' : '/signin'"
                  class="page-scroll hover:text-primaryColor my_font my_font_size"
                >
                  {{ $t(isSignedIn ? "my_account" : "sign_in") }}</router-link
                >
              </div>

              <!--Language dropdown on laptop screen-->
              <div class="relative hidden md:inline-block text-left px-2">
                <div class="">
                  <a
                    class="my_font my_font_size inline-flex justify-center items-center text-gray-700 duration-300 w-full px-3 py-3 cursor-pointer active:text-primaryColor hover:text-primaryColor dropdown-language"
                    @click="changeLamguageState"
                  >
                    <!-- <span>
                  Language
                </span>
                <span>
                  <svg class="fill-current h-4 w-4 transform group-hover:-rotate-180
                    transition duration-150 ease-in-out" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </span> -->
                    <img :src="$t('flag')" alt="" class="w-7 mx-1" />
                    {{ $t("language") }}
                  </a>
                </div>

                <div
                  class="origin-top-right absolute mt-2 w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 dropdown-language"
                  v-show="isShowLangauge"
                >
                  <div
                    class="py-1"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="language-dropdown"
                  >
                    <button
                      class="block px-2 py-2 text-sm text-gray-700 hover:bg-gray-100"
                      role="menuitem"
                      @click="changeLanguage('en')"
                    >
                      <div
                        class="my_font my_font_size inline-flex items-center"
                      >
                        <img
                          src="/assets/image/english.svg"
                          alt=""
                          class="w-8 mx-2"
                        />
                        English
                      </div>
                    </button>
                    <button
                      class="block px-2 py-2 text-sm text-gray-700 hover:bg-gray-100"
                      role="menuitem"
                      @click="changeLanguage('ku')"
                    >
                      <div
                        class="my_font my_font_size inline-flex items-center"
                      >
                        <img
                          src="/assets/image/kurdish.png"
                          alt=""
                          class="w-8 mx-2"
                        />
                        Kurdish
                      </div>
                    </button>
                    <button
                      class="block px-2 py-2 text-sm text-gray-700 hover:bg-gray-100"
                      role="menuitem"
                      @click="changeLanguage('ar')"
                    >
                      <div
                        class="my_font my_font_size inline-flex items-center"
                      >
                        <img
                          src="/assets/image/arabic.png"
                          alt=""
                          class="w-8 mx-2"
                        />
                        Arabic
                      </div>
                    </button>
                  </div>
                </div>
              </div>

              <div
                class="curser-pointer flex items-center justify-center bg-white"
                v-show="isSignedIn"
              >
                <router-link class="relative scale-75" to="/profile/cart">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="curser-pointer h-9 w-9 text-gray-600"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 00-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 00-16.536-1.84M7.5 14.25L5.106 5.272M6 20.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm12.75 0a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
                    />
                  </svg>
                  <span
                    class="absolute -top-2 left-5 rounded-full bg-red-500 p-0.2 px-2 text-sm text-red-50"
                    >{{ checkIfCartHasItems }}</span
                  >
                </router-link>
              </div>

              <div
                class="curser-pointer flex items-center justify-center bg-white"
                v-show="isSignedIn"
              >
                <router-link class="relative scale-75" to="/profile/favorites">
                  <svg
                    class="curser-pointer w-9 h-9 text-gray-600"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20.16 5A6.29 6.29 0 0 0 12 4.36a6.27 6.27 0 0 0-8.16 9.48l6.21 6.22a2.78 2.78 0 0 0 3.9 0l6.21-6.22a6.27 6.27 0 0 0 0-8.84Zm-1.41 7.46-6.21 6.21a.76.76 0 0 1-1.08 0l-6.21-6.24a4.29 4.29 0 0 1 0-6 4.27 4.27 0 0 1 6 0 1 1 0 0 0 1.42 0 4.27 4.27 0 0 1 6 0 4.29 4.29 0 0 1 .08 6Z"
                    />
                  </svg>
                </router-link>
              </div>

              <div
                class="curser-pointer flex items-center text-gray-600 justify-center bg-white"
                v-show="isSignedIn"
              >
                <router-link class="relative scale-75" to="/profile">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="curser-pointer w-10 h-10"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"
                    />
                  </svg>
                </router-link>
              </div>
            </div>
          </nav>
        </div>
      </div>
      <!-- Navbar End -->
    </header>
  </div>
  <div
    class="flex md:hidden mt-20 items-center border border-gray-400 rounded-4 px-4 py-2 mx-4"
    v-if="!checkIfProductPage()"
  >
    <input
      class="my_font my_font_size flex-1 text-gray-700 appearance-none bg-transparent focus:outline-none w-52"
      type="text"
      :placeholder="$t('search')"
      v-model="search"
      @input="searchFunc()"
      @keyup.enter="searchFuncGo"
    />
    <svg
      class="w-5 h-5 text-gray-700"
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      viewBox="0 0 24 24"
      stroke="currentColor"
      @click="searchFuncGo()"
    >
      <path d="M22 22l-6-6" />
      <circle cx="10" cy="10" r="8" />
    </svg>
  </div>
  <!--end seach field display on mobile-->

  <!--display when write text in search feild and in mobile-->
  <div
    class="hidden md:absolute mx-4 top-16 w-11/12 h-44 rounded-4 shadow-lg bg-white ring-1 ring-black ring-opacity-5 overflow-y-auto overscroll-y-contain"
    v-if="isSearchOpen"
  >
    <div
      class="py-1 w-full flex-col"
      v-for="(item, index) in searchList"
      :key="index"
    >
      <a
        :href="'/products/' + item.i_id"
        class="w-full flex items-center py-2 text-3 text-gray-700 hover:bg-gray-100 hover:text-gray-900"
      >
        <img
          :src="url + 'kcstore.online/public/storage/' + item.i_image"
          alt=""
          class="w-20 h-14 rounded-3 px-1"
        />
        {{ item.i_name }}</a
      >
    </div>
    <div
      class="my_font w-full flex items-center justify-center py-2 text-lg h-full text-gray-700 hover:bg-gray-100 hover:text-gray-900"
      v-if="searchList.length == 0 && isLoadSearch == false"
    >
      {{ $t("no_result_found") }}
    </div>
  </div>
</template>

<script>
import { SERVER_URL } from "../../config.js";

import axios from "axios";
export default {
  data() {
    return {
      isSearchOpen: false,
      search: "",
      url: SERVER_URL,
      categorys: [],
      isRtlVar: false,
      searchList: [],
      isLoadSearch: false,
      isShowNavbarToggle: false,
      isShowLangauge: false,
      isShowSubCategory: false,
      isShowCategory: false,
      user: {},
    };
  },
  computed: {
    isRtl() {
      return (
        localStorage.getItem("lang") == "ar" ||
        localStorage.getItem("lang") == "ku" ||
        //if null
        localStorage.getItem("lang") == null ||
        //if empty
        localStorage.getItem("lang") == ""
      );
    },
    isSignedIn() {
      const token = localStorage.getItem("token");
      if (!token) {
        return false;
      }
      return true;
    },
    checkIfCartHasItems() {
      var currentUser = this.$store.state.user;
      if (currentUser == null) return 0;
      if (
        currentUser.u_sabata == null ||
        currentUser.u_sabata == "" ||
        currentUser.u_sabata.length == 0
      ) {
        return 0;
      } else {
        var cartTemp = currentUser.u_sabata.toString();
        var cartArray = JSON.parse(cartTemp);
        return cartArray.length;
      }
    },
  },
  created() {
    this.$store.state.user = JSON.parse(localStorage.getItem("user"));
    this.getNavBarCategorys();
    this.isRtlVar = this.isRTLFunc();
    if (localStorage.getItem("user") != null)
      this.user = JSON.parse(localStorage.getItem("user"));
  },
  mounted() {
    // this.initNav();
    //listner for when every path param change
  },
  methods: {
    //function to log current path
    currentPath() {
      return this.$route.path;
    },
    checkIfProductPage() {
      if (this.$route.path == "/products") {
        return true;
      } else {
        return false;
      }
    },
    isRTLFunc() {
      return (
        localStorage.getItem("lang") == "ar" ||
        localStorage.getItem("lang") == "ku"
      );
    },
    changeLanguage(locale) {
      this.$i18n.locale = locale;

      localStorage.setItem("lang", locale);
      //reload page
      //window .href
      //check if path is product page
      if (this.$route.path == "/products") {
        window.location.href = "/products";
      } else {
        //reload page
        window.location.reload();
      }
    },
    initNav() {
      let navbarToggler = document.querySelector(".navbar-toggler");
      var navbarCollapse = document.querySelector(".navbar-collapse");

      navbarToggler.addEventListener("click", function () {
        navbarToggler.classList.toggle("active");
        navbarCollapse.classList.toggle("show");
      });
    },
    getNavBarCategorys() {
      axios
        .post(SERVER_URL + "api/home/get-navbar-category", {
          lang: localStorage.getItem("lang"),
        })
        .then((response) => {
          // this.items = response.data;
          this.categorys = response.data;
          //loop in  categorys keys
          for (const key in this.categorys) {
            if (Array.isArray(this.categorys[key])) {
              this.categorys[key].unshift({
                sc_name: this.$t("all") + " " + key,
              });
            }
          }

          this.categorys.all_categorys = [];

          const all_categorys = this.categorys.all_categorys;
          delete this.categorys.all_categorys;
          this.categorys = { all_categorys, ...this.categorys };
        })
        .catch((error) => {
          console.log(error);
        });
    },
    searchFunc() {
      this.isLoadSearch = true;
      this.search == "" || this.search == " "
        ? (this.isSearchOpen = false)
        : (this.isSearchOpen = true);
      axios
        .post(SERVER_URL + "api/item/search", {
          search: this.search,
        })
        .then((response) => {
          // this.items = response.data;
          this.searchList = response.data.item;

          this.isLoadSearch = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoadSearch = false;
        });
    },
    toggleNavbar() {
      document.addEventListener("click", (e) => {
        if (e.target.closest(".navbar-toggler")) return;
        this.isShowNavbarToggle = false;
      });

      this.isShowNavbarToggle = !this.isShowNavbarToggle;
      //close dropdwon when click outside
    },
    changeLamguageState() {
      document.addEventListener("click", (e) => {
        if (e.target.closest(".dropdown-language")) return;
        this.isShowLangauge = false;
      });

      this.isShowLangauge = !this.isShowLangauge;
      //close dropdwon when click outside
    },
    changeCategory() {
      document.addEventListener("click", (e) => {
        if (e.target.closest(".dropdown-category")) return;
        this.isShowCategory = false;
      });

      this.isShowCategory = !this.isShowCategory;
    },
    changeSubCategory(obj) {
      localStorage.setItem(
        "page",
        JSON.stringify({
          page: 1,
          date: Date.now(),
        })
      );
      //check empty object
      if (Object.keys(obj).length === 0) {
        window.location.href = "/products";

        return;
      }

      document.addEventListener("click", (e) => {
        if (e.target.closest(".dropdown-subCategory")) return;
        this.isShowSubCategory = false;
      });

      this.isShowSubCategory = !this.isShowSubCategory;
      //close dropdwon when click outside
    },
    searchFuncGo() {
      if (this.search == "" || this.search == " ") {
        return;
      }
      this.$router.push("/products?search=" + this.search);
    },
  },
};
</script>
<style scoped>
.dropdown-menu-sub {
  max-height: 600px;
}
.border-pink-500 {
  border-color: #e91e63;
}
.custom-right {
  right: 20.5rem;
}
.lighten-icon {
  filter: brightness(70%);
  /* or */
  /* color: #999; */
}
</style>
