<template>
  <metainfo>
    <template v-slot:title="{ content }">{{ content }}</template>
    <meta name="description" :content="description" />
  </metainfo>
  <Navbar />

  <!-- Start Slider Code -->
  <div class="swiper mySwiper mt-4 md:mt-20">
    <div class="swiper-wrapper">
      <!-- <div class="swiper-slide">
        <img class="w-full h-120 md:h-120 object-cover" src="/assets/image/slider.jpg" alt="image" />
      </div> -->

      <div
        class="min-w-full swiper-slide"
        v-for="(slider, index) in sliders"
        :key="index"
      >
        <img
          class="w-full h-full"
          :src="
            url +
            'kcstore.online/public/storage/' +
            (lang == 'en'
              ? slider.hl_image_en
              : lang == 'ar'
              ? slider.hl_image_ar
              : slider.hl_image)
          "
          alt="image"
          @click="sliderClick(slider)"
        />
      </div>
    </div>
    <div class="swiper-button-next"></div>
    <div class="swiper-button-prev"></div>
    <div class="swiper-pagination"></div>
  </div>
  <!--End SLider Code-->

  <div
    class="px-10 lg:px-16 py-10 mx-auto bg-gradient-to-br from-purple-100 to-white"
    data-aos="fade-up"
    data-aos-duration="2000"
  >
    <div class="items-center lg:flex">
      <div class="w-full lg:w-1/2">
        <div class="lg:max-w-lg">
          <h1 class="text-3xl text-gray-800 lg:text-4xl my_font">
            {{ $t("best_place") }}<br />{{ $t("your") }}
            <span
              class="tracking-tight bg-clip-text text-transparent bg-gradient-to-br from-primaryColorLight to-primaryColorDark my_font"
            >
              {{ $t("cosmatic") }}</span
            >
          </h1>

          <p class="my_font mt-3 text-gray-700 text-xl text-justify">
            {{ $t("about_detail") }}
          </p>

          <a
            class="my_font text-lg w-auto px-7 py-3 mt-6 font-medium uppercase tracking-tight bg-gradient-to-br from-primaryColorLight to-primaryColorDark text-white duration-300 rounded-4 lg:w-auto hover:animate-scale"
            href="/products"
          >
            {{ $t("shop_now") }}
          </a>
        </div>
      </div>

      <div
        class="flex items-center justify-center w-full mt-6 lg:mt-0 lg:w-1/2"
      >
        <img
          class="w-full h-full lg:max-w-3xl rounded-4"
          :src="'/assets/image/' + $t('back_to_home')"
          alt="Catalogue-pana.svg"
        />
      </div>
    </div>
  </div>

  <!--Start category-->
  <div
    class="my_font mx-auto text-center md:max-w-xl lg:max-w-3xl mt-20"
    data-aos="fade-up"
    data-aos-duration="1000"
  >
    <h3
      class="my_font mb-2 text-4xl my_font tracking-tight bg-clip-text text-transparent bg-gradient-to-br from-primaryColorLight to-primaryColorDark text-center py-2"
    >
      {{ $t("categories") }}
    </h3>
    <p class="my_font mb-5 pb-2 md:mb-12 md:pb-0 text-xl text-gray-600">
      {{ $t("our_categories") }}
    </p>
  </div>

  <div
    class="m-8 mx-4 md:mx-8 md:my-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-y-8 gap-x-4 justify-items-center items-center"
    data-aos="fade-up"
    data-aos-duration="2000"
  >
    <div
      class="relative flex min-w-88-precent flex-col rounded-xl bg-gradient-to-br from-purple-50 to-white text-gray-700 shadow-md hover:animate-scale"
      v-for="(category, index) in categories"
      :key="index"
    >
      <div
        class="relative m-auto h-60 w-60 mt-5 overflow-hidden rounded-full border-2 border-primaryColor object-center text-white shadow-lg shadow-blue-gray-500/40"
      >
        <img
          :src="url + 'kcstore.online/public/storage/' + category.ca_image"
          alt="image"
          class="absolute inset-0 w-10/12 object-contain object-center mx-auto my-auto"
          layout="fill"
        />
      </div>
      <div class="p-6">
        <h5
          class="mb-2 block text-2xl my_font text-center leading-snug tracking-normal text-blue-gray-900 antialiased"
        >
          {{ category.ca_name }}
        </h5>
      </div>
      <div class="p-6 pt-0 mx-auto">
        <a
          class="my_font select-none rounded-lg bg-gradient-to-br from-primaryColor to-primaryColorDark py-3 px-6 text-center align-middle text-base font-bold uppercase text-white shadow-md shadow-primaryColorLight transition-all hover:shadow-lg hover:shadow-primaryColor"
          type="button"
          data-ripple-light="true"
          :href="'/products' + '?category=' + category.ca_name"
        >
          {{ $t("view_items") }}
        </a>
      </div>
    </div>
  </div>

  <!-- <a href="">
      <div class="w-90 md:96 lg:w-120 rounded-lg overflow-hidden shadow-md bg-white">
        <img class="w-full h-48 object-cover" src="/assets/image/offer2.jpg" alt="Category Image">
        <div class="px-6 py-4">
          <h3 class="text-xl font-medium text-gray-800 text-center">Category Title</h3>
        </div>
      </div>
    </a> -->

  <!--End category -->

  <!--Start offer-->
  <div
    class="mx-auto text-center md:max-w-xl lg:max-w-3xl mt-20"
    data-aos="fade-up"
    data-aos-duration="1000"
  >
    <h3
      class="mb-2 text-4xl my_font tracking-tight bg-clip-text text-transparent bg-gradient-to-br from-primaryColorLight to-primaryColorDark text-center py-2"
    >
      {{ $t("home_part") }}
    </h3>
    <p class="my_font mb-5 pb-2 md:mb-12 md:pb-0 text-xl text-gray-600">
      {{ $t("list_of_home_part") }}
    </p>
  </div>

  <div
    class="m-8 md:mx-8 md:my-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-10 my_font items-center"
    data-aos="fade-up"
    data-aos-duration="2000"
  >
    <div
      class="min-w-82-precent min-h-75-precent"
      v-for="(part, index) in homeParts"
      :key="index"
    >
      <img
        :src="
          url +
          'kcstore.online/public/storage/' +
          (lang == 'en'
            ? part.hp_image_en
            : lang == 'ar'
            ? part.hp_image_ar
            : part.hp_image)
        "
        :alt="
          lang == 'en'
            ? part.hp_image_en
            : lang == 'ar'
            ? part.hp_image_ar
            : part.hp_image
        "
        class="w-full h-full rounded-4"
        @click="showHomePart(part)"
      />
    </div>
  </div>
  <!--End offer -->

  <!-- Friens Store-->
  <div class="my-20">
    <div
      class="flex w-96per items-center mx-auto mt-20 mb-5 swiper myFriendSwiper"
      data-aos="fade-up"
      data-aos-duration="2000"
    >
      <div class="mx-auto text-center md:max-w-xl lg:max-w-3xl">
        <h3
          class="my_font mb-2 text-4xl my_font tracking-tight bg-clip-text text-transparent bg-gradient-to-br from-primaryColorLight to-primaryColorDark text-center py-2"
        >
          {{ $t("testomonial") }}
        </h3>
        <p class="my_font mb-5 pb-2 md:mb-12 md:pb-0 text-xl text-gray-600">
          {{ $t("customers_love_kc_store") }}
        </p>
      </div>
      <div class="mb-16 mt-10 swiper-wrapper items-center justify-items-center">
        <!--first Testimonial-->
        <div
          class="swiper-slide"
          v-for="(review, index) in reviews"
          :key="index"
        >
          <div class="mb-8 bg-purple-50 rounded-4 shadow grid">
            <div class="relative z-20 px-2 py-6 -mt-14">
              <span
                class="inline-block p-3 mb-3 text-xs text-white bg-gradient-to-br from-primaryColor to-purple-700 rounded-full"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  fill="currentColor"
                  class="bi bi-quote"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M12 12a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1h-1.388c0-.351.021-.703.062-1.054.062-.372.166-.703.31-.992.145-.29.331-.517.559-.683.227-.186.516-.279.868-.279V3c-.579 0-1.085.124-1.52.372a3.322 3.322 0 0 0-1.085.992 4.92 4.92 0 0 0-.62 1.458A7.712 7.712 0 0 0 9 7.558V11a1 1 0 0 0 1 1h2Zm-6 0a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1H4.612c0-.351.021-.703.062-1.054.062-.372.166-.703.31-.992.145-.29.331-.517.559-.683.227-.186.516-.279.868-.279V3c-.579 0-1.085.124-1.52.372a3.322 3.322 0 0 0-1.085.992 4.92 4.92 0 0 0-.62 1.458A7.712 7.712 0 0 0 3 7.558V11a1 1 0 0 0 1 1h2Z"
                  />
                </svg>
              </span>
              <p
                class="my_font mb-4 text-base leading-7 text-gray-600 overflow-y-auto h-30 overflow-x-hidden no-scrollbar"
              >
                {{
                  lang == "en"
                    ? review.sf_desc_en
                    : lang == "ar"
                    ? review.sf_desc_ar
                    : review.sf_desc
                }}
              </p>
              <div class="flex items-center gap-x-4">
                <div class="relative w-14 h-14 overflow-hidden rounded-full">
                  <img
                    class="object-cover w-full h-full transition-all hover:scale-110"
                    :src="
                      url + 'kcstore.online/public/storage/' + review.sf_image
                    "
                    alt=""
                  />
                </div>
                <div class="info">
                  <h2 class="text-lg font-bold text-black">
                    {{ review.sf_name }}
                  </h2>
                </div>
              </div>
              <button
                class="my_font flex my-2 mx-auto items-center rounded-lg py-3 px-6 text-center align-middle text-base font-bold uppercase text-white bg-gradient-to-br from-primaryColor to-primaryColorDark"
                type="button"
                @click="openLink(review.sf_link)"
              >
                {{ $t("view_profile") }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="swiper-pagination"></div>
    </div>
  </div>

  <Footer />
</template>

<script type="application/javascript">
import Navbar from "./components/nav-bar.vue";
import Footer from "./components/footer-app.vue";
import Swiper from "swiper";
import { SERVER_URL } from "../config.js";

import { Navigation, Pagination, Scrollbar } from "swiper";
import "swiper/swiper-bundle.min.css";
import axios from "axios";
import { useMeta } from "vue-meta";

export default {
  name: "App",
  setup() {
    useMeta({
      title: "پەڕەی سەرەکی",
      description:
        "پێستت بەشێکە لە تەندروستیت، تەندورستی پێستت بە ئێمە بسپێرە، بەهەبوونی زیاتر لە 2 ساڵ کارکردن لە بوارەکەدا و دابینکردنی بەناوبانگترین و چاکترین بەرهەمەکان لە وڵاتانی کۆریا و یابان و ولاتانی ئەوروپاوە، دەتوانین یارمەتیت بدەین بۆ بەدەستهێنانی پێستی دڵخواز و تەندروستی خۆت",
      //image
    });
  },
  components: {
    Navbar,
    Footer,
  },
  data() {
    return {
      sliders: [],
      url: SERVER_URL,
      categories: [],
      homeParts: [],
      reviews: [],
      lang: localStorage.getItem("lang"),
    };
  },

  created() {
    //await this.getSliders();
    this.getSldiers();
    window.scrollTo(0, 0);
  },
  mounted() {
    this.initSwipers();
  },

  methods: {
    initSwipers() {
      var reviews=new Swiper(".myFriendSwiper", {
        //in mobile view 2 slides
        breakpoints: {
          0: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          //in tab view 3 slides
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          //in desktop view 4 slides
          1024: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          1400: {
            slidesPerView: 4,
            spaceBetween: 30,
          },
        },
        grid: {
          rows: 1,
        },
        spaceBetween: 30,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        keyboard: true,
        speed: 1000,
      });

      // eslint-disable-next-line no-unused-vars
      var homeSlider = new Swiper(".mySwiper", {
        slidesPerView: "auto",
        spaceBetween: 16,
        modules: [Navigation, Pagination, Scrollbar],

        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        pagination: {
          el: ".swiper-pagination",
        },
        keyboard: true,
        loop: true,
        speed: 1000,
      });
      //change swiper slide every 5 seconds .mySwiper home slider
      setInterval(() => {
        homeSlider.slideNext();
      }, 5000);
      setInterval(() => {
        reviews.slideNext();
      }, 7000);
    },
    getSldiers() {
      axios
        .post(SERVER_URL + "api/home/get-slider", {
          lang: this.lang,
        })
        .then((response) => {
          // this.items = response.data;
          this.sliders = response.data;

          //update loading state

          this.getCategory();
        })
        .catch((error) => {
          console.log(error);
          this.getCategory();
        });
    },
    getCategory() {
      axios
        .post(SERVER_URL + "api/home/get-category", {
          lang: localStorage.getItem("lang"),
        })
        .then((response) => {
          // this.items = response.data;
          this.categories = response.data;

          this.getHomeParts();
        })
        .catch((error) => {
          console.log(error);
          this.getHomeParts();
        });
    },
    getHomeParts() {
      axios
        .post(SERVER_URL + "api/home/get-part", {
          lang: this.lang,
        })
        .then((response) => {
          // this.items = response.data;
          this.homeParts = response.data;

          this.getFriendStores();
        })
        .catch((error) => {
          console.log(error);
          this.getFriendStores();
        });
    },
    getFriendStores() {
      axios
        .post(SERVER_URL + "api/home/get-review", {
          lang: this.lang,
        })
        .then((response) => {
          // this.items = response.data;
          this.reviews = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    sliderClick(slider) {
      //check slider.hl_action
      if (slider.hl_action == "link") {
        //open link
        window.open(slider.hl_content);
      } else if (slider.hl_action == "phone") {
        //open phone
        window.open("tel:" + slider.hl_content);
      }
    },
    showHomePart(homePart) {
      window.open(homePart.hp_link);
    },
    openLink(link) {
      window.open(link);
    },
  },
};
</script>

<style scoped>
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
</style>
