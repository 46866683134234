<template>
  <metainfo>
    <template v-slot:title="{ content }">{{ content }}</template>
    <meta name="description" :content="description" />
  </metainfo>

  <Navbar />

  <div
    class="md:mt-24 mt-4 px-10 lg:px-16 py-10 mx-auto bg-gradient-to-br from-purple-100 to-white"
    data-aos="fade-up"
    data-aos-duration="2000"
  >
    <div class="items-center lg:flex">
      <div class="w-full lg:w-1/2">
        <div class="lg:max-w-lg">
          <h1 class="text-3xl text-gray-800 lg:text-4xl my_font">
            {{ $t("best_place") }}<br />{{ $t("your") }}
            <span
              class="tracking-tight bg-clip-text text-transparent bg-gradient-to-br from-primaryColorLight to-primaryColorDark my_font"
            >
              {{ $t("cosmatic") }}</span
            >
          </h1>

          <p class="my_font mt-3 text-gray-700 text-xl text-justify">
            {{ $t("about_detail") }}
          </p>

          <a
            class="my_font text-lg w-auto px-7 py-3 mt-6 font-medium uppercase tracking-tight bg-gradient-to-br from-primaryColorLight to-primaryColorDark text-white duration-300 rounded-4 lg:w-auto hover:animate-scale"
            href="/products"
          >
            {{ $t("shop_now") }}
          </a>
        </div>
      </div>

      <div
        class="flex items-center justify-center w-full mt-6 lg:mt-0 lg:w-1/2"
      >
        <img
          class="w-full h-full lg:max-w-3xl rounded-4"
          :src="'/assets/image/' + $t('back_to_home')"
          :alt="$t('back_to_home')"
        />
      </div>
    </div>
  </div>

  <!--Start contact us section-->

  <div
    class="mx-auto text-center md:max-w-xl lg:max-w-3xl mt-20"
    data-aos="fade-up"
    data-aos-duration="1000"
  >
    <h3
      class="my_font mb-2 text-4xl font-semibold tracking-tight bg-clip-text text-transparent bg-gradient-to-br from-primaryColorLight to-primaryColorDark text-center py-2"
    >
      {{ $t("contact_us") }}
    </h3>
    <p class="my_font mb-5 pb-2 md:mb-12 md:pb-0 text-lg text-gray-600">
      {{ $t("contact_us_with_our_social_media") }}
    </p>
  </div>
  <div
    class="flex justify-center mx-auto"
    data-aos="fade-up"
    data-aos-duration="1500"
  >
    <div
      class="mx-4 md:mx-4 lg:mx-8 grid grid-cols-1 gap-12 mb-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3"
    >
      <a href="https://www.facebook.com/koreancosmeticstore2021">
        <div
          class="p-6 text-center rounded-lg bg-purple-100 hover:animate-scale"
        >
          <span class="inline-block p-3 rounded-lg bg-purple-200 mt-2 lg:mt-7">
            <img src="/assets/image/facebook.svg" alt="" class="w-6 h-6" />
          </span>

          <h2 class="mt-4 text-base font-medium text-gray-800">
            Facebook.com/koreancosmeticstore2021
          </h2>
          <p class="my_font mt-2 text-base text-gray-600">
            {{ $t("contact_us_with_facebook") }}
          </p>
        </div>
      </a>

      <a href="https://www.instagram.com/korean_c_store_/">
        <div
          class="p-6 text-center rounded-lg bg-purple-100 hover:animate-scale"
        >
          <span
            class="inline-block p-3 text-white rounded-lg bg-purple-200 mt-2 lg:mt-7"
          >
            <img src="/assets/image/instagram.svg" alt="" class="w-6 h-6" />
          </span>

          <h2 class="mt-4 text-base font-medium text-gray-800">
            Instagram.com/korean_c_store_
          </h2>
          <p class="my_font mt-2 text-base text-gray-600">
            {{ $t("contact_us_with_instagram") }}
          </p>
        </div>
      </a>

      <a href="tel:+9647512308652">
        <div
          class="p-6 text-center rounded-lg bg-purple-100 hover:animate-scale"
        >
          <span
            class="inline-block p-3 text-primaryColorDark rounded-lg bg-purple-200 mt-2 lg:mt-7"
          >
            <img src="/assets/image/phone.svg" alt="" class="w-7 h-7" />
          </span>

          <h2 class="my_font my_font mt-4 text-base font-medium text-gray-800">
            0751-230-8652
          </h2>
          <p class="my_font mt-2 text-base text-gray-600">
            {{ $t("contact_us") }}
          </p>
        </div>
      </a>

      <!-- <div class="p-4 rounded-lg bg-blue-50 md:p-6 dark:bg-gray-800">
        <span class="inline-block p-3 text-blue-500 rounded-lg bg-blue-100/80 dark:bg-gray-700">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
            class="w-5 h-5">
            <path stroke-linecap="round" stroke-linejoin="round"
              d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z" />
          </svg>
        </span>
  
        <h2 class="mt-4 text-base font-medium text-gray-800 dark:text-white">Call us</h2>
        <p class="mt-2 text-sm text-gray-500 dark:text-gray-400">Mon-Fri from 8am to 5pm.</p>
        <p class="mt-2 text-sm text-blue-500 dark:text-blue-400">+1 (555) 000-0000</p>
      </div> -->
    </div>
  </div>

  <Footer />
</template>

<script type="application/javascript">
import Navbar from "./components/nav-bar.vue";
import Footer from "./components/footer-app.vue";
import { useMeta } from "vue-meta";

export default {
  name: "App",

  setup() {
    useMeta({
      title: "دەربارەی ئێمە",
      description:
        "پێستت بەشێکە لە تەندروستیت، تەندورستی پێستت بە ئێمە بسپێرە، بەهەبوونی زیاتر لە 2 ساڵ کارکردن لە بوارەکەدا و دابینکردنی بەناوبانگترین و چاکترین بەرهەمەکان لە وڵاتانی کۆریا و یابان و ولاتانی ئەوروپاوە، دەتوانین یارمەتیت بدەین بۆ بەدەستهێنانی پێستی دڵخواز و تەندروستی خۆت",
      //image
    });
  },
  components: {
    Navbar,
    Footer,
  },
  created() {
    
    //  this.initializeSwiper();
    //   this.initializeFriendSwiper();
    window.scrollTo(0, 0);
  },
  mounted() {},
  methods: {},
};
</script>
